<template>
    <transition name="slide" mode="out-in">
        <div class="register-success-tbf forgot-modal">
            <div class="success-modal">
                <div class="content-modal">
                    <div class="content-icon reset">
                        <img src="/build/flappy/bird_key.png" class="reset"/>
                    </div>
                    <div class="title forgot">
                        <span>{{ $t('reset_password.title') }}</span>
                        <div class="btn-tbf only-icon white" @click="redirect()">
                            <icon-close />
                        </div>
                    </div>
                    <div class="container-content-modal">
                        <div class="form-section-register">
                            <div class="container-message forgot">
                                {{ $t('reset_password.description') }}
                            </div>

                            <div class="input-login-group-tbf w-tbf-100" v-bind:class="{has_error: $v.email.$error, complete: email != '' && !$v.email.$error }">
                                <div class="label-input">
                                    <label>{{$t('reset_password.email')}}</label>
                                </div>
                                <div class="input-box">
                                    <div class="icon-left"><icon-email /></div>
                                    <div class="icon-right" v-if="email != ''" @click.stop="email = ''"><icon-close  /></div>
                                    <input type="email" :placeholder="$t('reset_password.email-placeholder')" class="input-text" v-model="email">
                                </div>
                            </div>

                            <div class="input-login-group-tbf w-tbf-100" v-bind:class="{has_error: $v.password.$error, complete: password != '' && !$v.password.$error}">
                                <div class="label-input">
                                    <label>{{$t('reset_password.password')}}</label>
                                    <div class="error-msg" v-if="!$v.password.minLength">{{ $t('validator.password_min_length') }}</div>
                                    <div class="error-msg" v-else-if="!$v.confirm_password.sameAsPassword">{{ $t('validator.same_password') }}</div>
                                </div>
                                <div class="input-box">
                                    <div class="icon-left"><icon-password /></div>
                                    <div class="icon-right" v-if="password != ''" @click.stop="password = ''"><icon-close  /></div>
                                    <input type="password" :placeholder="$t('reset_password.password-placeholder')" class="input-text" v-model="password">
                                </div>
                            </div>

                            <div class="input-login-group-tbf w-tbf-100" v-bind:class="{has_error: $v.confirm_password.$error, complete: confirm_password == password && !$v.confirm_password.$error}">
                                <div class="label-input">
                                    <label>{{$t('reset_password.password-confirm')}}</label>
                                </div>
                                <div class="input-box">
                                    <div class="icon-left"><icon-password /></div>
                                    <div class="icon-right" v-if="confirm_password != ''" @click.stop="confirm_password = ''"><icon-close  /></div>
                                    <input type="password" :placeholder="$t('reset_password.password-confirm-placeholder')" class="input-text" v-model="confirm_password">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="action-form">
                        <button id="buttonSubmit" class="btn-tbf blue center" @click="resetPassword">
                            <div class="loader"></div>
                            <div class="text">{{ $t('reset_password.button-action') }}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import IconClose from '../Icons/Close'
import IconPassword from '../Icons/Password'
import IconEmail from '../Icons/Email'

import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
	data(){
		return {
            email: "",
            password: "",
            confirm_password: ""
		}
    },
    components: {
        IconClose,
        IconPassword,
        IconEmail,
    },
    validations: {
        email: {required},
		password: {required,minLength: minLength(6)},
		confirm_password: {required, sameAsPassword: sameAs('password')}
	},
	methods: {
        resetPassword() {
            var buttonName = 'buttonSubmit'
            var btnSubmit = document.getElementById(buttonName);
            var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
            var btnSubmitText = document.querySelector(`#${buttonName} .text`)

            btnSubmit.disabled = true

            btnSubmit.classList.add('loading')
            btnSubmitLoader.classList.add('onProgress')
            btnSubmitText.innerHTML = this.$t('btn-submit.loading')

            this.$v.$touch()

            if(!this.$v.$invalid){
                axios.post("/auth/reset/password", {
                    token: this.$route.params.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.confirm_password
                })
                .then(result => {
                    btnSubmitLoader.classList.add('finish')
                    setTimeout(()=>{
                        btnSubmitText.innerHTML = this.$t('btn-submit.success')
                        btnSubmit.classList.add('completed')
                        btnSubmitLoader.classList.remove('onProgress', 'finish')
                        btnSubmit.classList.remove('loading')
                        setTimeout(()=>{
                            btnSubmit.classList.remove('completed')
                            this.$router.push({ name: 'password-reset-success' });
                        }, 1000)
                    }, 300)
                }).catch(error => {
                    btnSubmitLoader.classList.add('finish')
                    setTimeout(()=>{
                        btnSubmitText.innerHTML = this.$t('btn-submit.error')
                        btnSubmit.classList.add('error')
                        btnSubmitLoader.classList.remove('onProgress', 'finish')
                        btnSubmit.classList.remove('loading')
                        setTimeout(()=>{
                            btnSubmit.classList.remove('error')
                            btnSubmitText.innerHTML = this.$t('reset_password.button-action')
                            btnSubmit.disabled = false
                            if(error.response) {
                                if(error.response.status == 500) {
                                    alert(this.$t('error.500'))
                                }
                            }
                        }, 1000)
                    }, 300)
                });
            }else{
                btnSubmitLoader.classList.add('finish')
                setTimeout(()=>{
                    btnSubmitText.innerHTML = this.$t('btn-submit.error')
                    btnSubmit.classList.add('error')
                    btnSubmitLoader.classList.remove('onProgress', 'finish')
                    btnSubmit.classList.remove('loading')
                    setTimeout(()=>{
                        btnSubmit.classList.remove('error')
                        btnSubmitText.innerHTML = this.$t('reset_password.button-action')
                        btnSubmit.disabled = false
                    }, 1000)
                }, 300)
            }
        },
        redirect() {
            this.$router.push("/login");
        }
	}
}
</script>

<style lang="scss">
    .forgot-modal{
        .content-modal{
            .action-form{
                margin-top: 0;
            }
        }
    }
</style>